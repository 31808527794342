<template>
    <div>
        <el-tiptap
            lang="zh"
            :height="height"
            placeholder="请输入内容"
            v-model="content"
            :extensions="extensions"
            :readonly="false"
        ></el-tiptap>
    </div>
</template>

<script>
import {
    ElementTiptap,
    Doc,
    Paragraph,
    Text,
    Heading,
    Bold,
    Italic,
    Underline,
    Strike,
    // FontType,
    FontSize,
    TextColor,
    TextHighlight,
    FormatClear,
    ListItem,
    BulletList,
    OrderedList,
    TodoItem,
    TodoList,
    Table,
    TableHeader,
    TableRow,
    TableCell,
    //   CodeBlock,
    //   Blockquote,
    // Link,
    //   Image,
    //   Iframe,
    TextAlign,
    // LineHeight,
    // Indent,
    // HorizontalRule,
    HardBreak,
    TrailingNode,
    History,
    Preview,
    // Print,
    // SelectAll,
    // Fullscreen,
    //   CodeView
} from 'element-tiptap'

import 'element-tiptap/lib/index.css'

export default {
    name: 'CommonEditorComponent',
    data() {
        return {
            content: this.fcontent,
            extensions: [
                new Doc(),
                new Paragraph(),
                new Text(),
                new Heading({ level: 5 }), //标题，最多几级
                new Bold(), //粗体
                new Italic(), //斜体
                new Underline(), // 下划线
                new Strike(), //删除线
                // new FontType(), //字体
                new FontSize(), //字号
                new TextColor(), //文本颜色
                new TextHighlight(), //文本高亮
                new FormatClear(), //清除格式
                new ListItem(), //列表必须要的
                new BulletList(), //无序列表
                new OrderedList(), //有序列表
                new TodoItem(), //多选框列表必备
                new TodoList(), //多选框列表
                new Table({ resizable: true }), //表格组件必备,可调整表格大小
                new TableHeader(), //表格组件必备
                new TableRow(), //表格列相关
                new TableCell(), //表格行相关
                // new CodeBlock(),    //代码块
                // new Blockquote(),   //引用
                // new Link(), //超链接

                // new Iframe(),   //视频
                new TextAlign(), //对齐方式
                // new LineHeight(), //行高
                // new Indent(), //增加缩进
                // new HorizontalRule(), //减少缩进
                new HardBreak(), //分割线
                new TrailingNode(), //撤销
                new History(), //前进
                new Preview(), //预览
                // new Print(), //打印
                // new SelectAll(), //全选
                // new Fullscreen(), //全屏
                // new CodeView()  //查看源代码
            ],
        }
    },
    computed: {},
    methods: {},
    filters: {},
    props: {
        fcontent: {
            type: String,
            default() {
                return ''
            },
        },
        height: {
            type: String,
            default() {
                return '340px'
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        'el-tiptap': ElementTiptap,
    },
    watch: {
        fcontent(newVal) {
            this.content = newVal
        },
    },
}
</script>

<style lang='less'></style>